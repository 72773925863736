/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import ListItem from 'framework7-react/components/list-item'
import Button from 'framework7-react/components/button'
import Icon from 'framework7-react/components/icon'
import Actions from 'framework7-react/components/actions'
import ActionsLabel from 'framework7-react/components/actions-label'
import ActionsGroup from 'framework7-react/components/actions-group'
import Block from 'framework7-react/components/block'
import ActionsButton from 'framework7-react/components/actions-button'
import BlockTitle from 'framework7-react/components/block-title'
import List from 'framework7-react/components/list'
import { getDistanceFromLatLonInKm } from '../utils/utils'

const mapsLink = 'https://www.google.com/maps/dir/?api=1&destination='

const getPhones = (phone) => {
  const onlyPhones = phone.split('Teléfono ').pop()
  return onlyPhones.split('-')
}

const FarmaciaDetail = ({ farm, currentPosition, allLive, setActionsData }) => {
  return (
    <ListItem
      onClick={() => setActionsData(farm)}
      link="#"
      header={farm.nombre}
      title={farm.direccion.split('Dirección ').pop()}
      after={currentPosition
        ? `${getDistanceFromLatLonInKm(farm.coords.lat, farm.coords.lng, currentPosition.lat, currentPosition.lng)}m aprox`
        : ''
    }>
        {farm.live || farm.open
          ? <Icon slot="media" color='green' f7="graph_circle" />
          : <Icon slot="media" color='white' f7="moon_zzz_fill" />
        }
      {farm.live && <span style={{ color: 'var(--f7-color-primary)' }} slot="footer">De turno</span>}
    </ListItem>
  )
}

const SortedFarmacias = ({ sortedFarms, notSortedFarms, title, currentPosition, max }) => {
  const [actionsData, setActionsData] = useState({})
  const [showAllElements, setShowAllElements] = useState(!max)
  const farmsList = sortedFarms.length > 0 ? sortedFarms : notSortedFarms
  // if we need to display a max qty by default, we need to toggle between show all / show max
  const slicedFarmList = farmsList.slice(0, !showAllElements ? max : farmsList.length)
  return (
  <>
    <BlockTitle>{title}</BlockTitle>
    <List dividersIos outlineIos strongIos>
      <ul>
        {slicedFarmList.map(farm =>
          <FarmaciaDetail
            setActionsData={setActionsData}
            key={`all-${farm.direccion}-${farm.nombre}`}
            farm={farm}
            currentPosition={currentPosition} />
        )}
      </ul>
    </List>
    <Actions
      opened={Boolean(actionsData.nombre)}
      onActionsClosed={() => setActionsData({})}
    >
      <ActionsGroup>
        <ActionsLabel>{actionsData.nombre}</ActionsLabel>
        <ActionsButton strong onClick={() => {
          window.open(`${mapsLink}${actionsData?.coords?.lat},${actionsData?.coords?.lng}`, '_self')
        }}>
          Ir
        </ActionsButton>
        {actionsData.telefono && getPhones(actionsData.telefono).map((tel, i) =>
          <ActionsButton key={`${i}-${tel}`} onClick={() => {
            window.open(`tel:${tel}`, '_self')
          }}>
            Llamar por teléfono ({tel})
          </ActionsButton>)
        }
        </ActionsGroup>
        <ActionsGroup>
          <ActionsButton color="red">Cancelar</ActionsButton>
      </ActionsGroup>
    </Actions>
    {max && !showAllElements &&
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button tonal onClick={() => setShowAllElements(true)}>
          Mostrar todas
        </Button>
      </div>
    }
    <Block/>
  </>
  )
}

export default SortedFarmacias
