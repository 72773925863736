export const limiter = (fn, wait) => {
  let isCalled = false
  return function (done) {
    if (!isCalled) {
      fn(done)
      isCalled = true
      setTimeout(function () {
        isCalled = false
      }, wait)
    } else {
      done()
    }
  }
}

export const dateWithDay = () => {
  const d = new Date()
  const day = d.toLocaleDateString(window.navigator.language, { weekday: 'long' })
  const date = d.toLocaleDateString()
  const time = d.toLocaleTimeString()
  return `${day}, ${date}, ${time}`
}

export const isBussinesHours = () => {
  /* const start = 9 * 60
  const end = 20 * 60
  const date = new Date()
  const now = date.getHours() * 60 + date.getMinutes()
  const isWeekend = date.getDay() === 6 || date.getDay() === 0
  return start <= now && now <= end && !isWeekend */
  return false
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d.toFixed(1) * 1000 // in m;
}

function deg2rad (deg) {
  return deg * (Math.PI / 180)
}
