import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import './App.css'
import 'framework7/css'
import Framework7 from 'framework7/lite'

// added non core components
import Actions from 'framework7/components/actions'
import PullToRefresh from 'framework7/components/pull-to-refresh'
import Framework7React from 'framework7-react'

// styling for non core components
import 'framework7/components/preloader/css'
import 'framework7/components/pull-to-refresh/css'
import 'framework7/components/actions/css'
require('default-passive-events')

Framework7.use([Actions, PullToRefresh, Framework7React])

const root = ReactDOM.createRoot(document.getElementById('app'))

root.render(
    <App device={Framework7.device} />
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
