/* eslint-disable react/prop-types */
// App.jsx
import { React, useEffect, useState, useCallback } from 'react'
import App from 'framework7-react/components/app'
import Block from 'framework7-react/components/block'
import BlockTitle from 'framework7-react/components/block-title'
import View from 'framework7-react/components/view'
import Navbar from 'framework7-react/components/navbar'
import NavRight from 'framework7-react/components/nav-right'
import Page from 'framework7-react/components/page'
import Link from 'framework7-react/components/link'
import { f7ready } from 'framework7-react/shared/f7'
import FarmaciasList from './components/FarmaciasLIst'
import useInstall from './installButton'
import { limiter, dateWithDay, getDistanceFromLatLonInKm, isBussinesHours } from './utils/utils'
import { NavTitle } from 'framework7-react'
const business = isBussinesHours()

const AppWrapper = ({ device }) => {
  const install = useInstall()
  const [farms, setFarms] = useState([])
  const [otherFarms, setOtherFarms] = useState([])
  const [allFarms, setAllFarms] = useState([])
  const [sortedFarms, setSortedFarms] = useState([])
  const [sortedOtherFarms, setSortedOtherFarms] = useState([])
  const [sortedAllFarms, setSortedAllFarms] = useState([])
  const [currentPosition, setCurrentPosition] = useState(null)

  const getData = async (done) => {
    try {
      const response = await fetch('farmacias.json')
      const farmacias = await response.json()
      const liveFarms = farmacias.farmacias.map(f => {
        f.live = true
        f.open = business
        return f
      })
      const otherFarms = farmacias.otherFarmacias.map(f => {
        f.open = business
        return f
      })
      setFarms(liveFarms)
      setOtherFarms(otherFarms)
      setAllFarms([...liveFarms, ...otherFarms])
      done?.()
    } catch (e) {
      console.log(e)
      done?.()
    }
  }

  const limitedGetData = useCallback(limiter(getData, 30000), [])

  useEffect(() => {
    f7ready(async (f7) => {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      }, null, { enableHighAccuracy: true })
      f7.setDarkMode(true)
      f7.setColorTheme('#4cd964')
      await getData()
    })
  }, [])

  useEffect(() => {
    if (currentPosition) {
      const farmacias = [...farms].sort((a, b) =>
        getDistanceFromLatLonInKm(a.coords.lat, a.coords.lng, currentPosition.lat, currentPosition.lng) -
        getDistanceFromLatLonInKm(b.coords.lat, b.coords.lng, currentPosition.lat, currentPosition.lng)
      )
      const otherFarmacias = [...otherFarms].sort((a, b) =>
        getDistanceFromLatLonInKm(a.coords.lat, a.coords.lng, currentPosition.lat, currentPosition.lng) -
        getDistanceFromLatLonInKm(b.coords.lat, b.coords.lng, currentPosition.lat, currentPosition.lng)
      )
      const allFarmacias = [...allFarms].sort((a, b) =>
        getDistanceFromLatLonInKm(a.coords.lat, a.coords.lng, currentPosition.lat, currentPosition.lng) -
        getDistanceFromLatLonInKm(b.coords.lat, b.coords.lng, currentPosition.lat, currentPosition.lng)
      )
      setSortedFarms(farmacias)
      setSortedOtherFarms(otherFarmacias)
      setSortedAllFarms(allFarmacias)
    }
  }, [allFarms, currentPosition])
  return (
  <App theme="auto" name="Farmacias La Plata">
    <View main>
      <Page
        onPtrRefresh={
          (done) => {
            navigator.geolocation.getCurrentPosition((position) => {
              setCurrentPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
            }, null, { enableHighAccuracy: true })
            limitedGetData(done)
          }
        }
        ptr
        ptrMousewheel
      >
        <Navbar>
          <NavTitle><h1 style={{ fontSize: '22px', fontWeight: '400' }}>Farmacias de turno</h1></NavTitle>
        { device.android && !device.standalone &&
          <NavRight>
            <Link onClick={install} iconF7='cloud_download' iconColor='green' />
          </NavRight>
        }
        </Navbar>
        {/* Page content */}
        <BlockTitle large>La Plata</BlockTitle>
        <Block>
          <p>{dateWithDay()}</p>
        </Block>
        {business
          ? <FarmaciasList
            sortedFarms={sortedAllFarms}
            notSortedFarms={allFarms}
            title={'Farmacias cercanas abiertas'}
            currentPosition={currentPosition}
            max={40}
          />
          : <>
        <FarmaciasList
          sortedFarms={sortedFarms}
          notSortedFarms={farms}
          title={'Farmacias cercanas abiertas'}
          currentPosition={currentPosition}
        />
        <FarmaciasList
          sortedFarms={sortedOtherFarms}
          notSortedFarms={otherFarms}
          title={'Otras farmacias'}
          currentPosition={currentPosition}
          max={20}
        />
        </>
        }
      </Page>
    </View>
  </App>
  )
}

export default AppWrapper
